import { API } from '@huya-sentry/core';
import { Event, Response, Transport, TransportOptions } from '@huya-sentry/types';
import { PromiseBuffer, SentryError } from '@huya-sentry/utils';

/** Base Transport class implementation */
export abstract class BaseTransport implements Transport {
  /**
   * @inheritDoc
   */
  public url: string;
  public hscanUrl: string;

  /** A simple buffer holding all requests. */
  protected readonly _buffer: PromiseBuffer<Response> = new PromiseBuffer(30);

  public constructor(public options: TransportOptions) {
    this.url = new API(this.options.dsn, this.options.rpurl).getStoreEndpointWithUrlEncodedAuth();
    this.hscanUrl = 'https://report-hscan.huya.com/report/report-track';
  }

  /**
   * @inheritDoc
   */
  public sendEvent(_: Event): Promise<Response> {
    throw new SentryError('Transport Class has to implement `sendEvent` method');
  }

  /**
   * @inheritDoc
   */
  public close(timeout?: number): Promise<boolean> {
    return this._buffer.drain(timeout);
  }
}
