import { DsnLike } from '@huya-sentry/types';
import { urlEncode } from '@huya-sentry/utils';
import * as U from 'url';
const urlParser = U.parse;
import { Dsn } from './dsn';

export const SENTRY_API_VERSION = '7';

/** Helper class to provide urls to different Sentry endpoints. */
export class API {
  /** The internally used Dsn object. */
  private readonly _dsnObject: Dsn;
  /** Create a new instance of API */
  public constructor(public dsn: DsnLike, public rpurl: string = 'https://ylog.huya.com/d.gif') {
    this._dsnObject = new Dsn(dsn);
  }

  /** Returns the Dsn object. */
  public getDsn(): Dsn {
    return this._dsnObject;
  }

  /** Returns a string with auth headers in the url to the store endpoint. */
  public getStoreEndpoint(): string {
    return `${this._getBaseUrl()}${this.getStoreEndpointPath()}`;
  }

  /** Returns the store endpoint with auth added in url encoded. */
  public getStoreEndpointWithUrlEncodedAuth(): string {
    const dsn = this._dsnObject;
    const auth = {
      sentry_key: dsn.user,
      sentry_version: SENTRY_API_VERSION,
    };
    // Auth is intentionally sent as part of query string (NOT as custom HTTP header)
    // to avoid preflight CORS requests
    return `${this.getStoreEndpoint()}?${urlEncode(auth)}`;
  }

  /** Returns the ylog store endpoint with auth added in url encoded. */
  public getYlogPathWithUrlEncodeAuth(): string {
    const dsn = this._dsnObject;
    const auth = {
      sentry_key: dsn.user,
      sentry_version: SENTRY_API_VERSION,
    };
    // Auth is intentionally sent as part of query string (NOT as custom HTTP header)
    // to avoid preflight CORS requests
    return `/d.gif?${urlEncode(auth)}`;
  }

  /** Returns the base path of the url including the port. */
  private _getBaseUrl(): string {
    // const dsn = this._dsnObject;
    // const protocol = dsn.protocol ? `${dsn.protocol}:` : '';
    // const port = dsn.port ? `:${dsn.port}` : '';
    // return `${protocol}//${dsn.host}${port}`;

    // change to own report api
    // notice: dialog can't be used
    // return 'https://ylog.huya.com/d.gif';
    // return 'https://ylog.nimo.tv/d.gif';
    return this.rpurl;
  }

  /** return rpurl‘s hostname */
  public getRpHostName(): string | undefined {
    const url = urlParser(this.rpurl);
    return url.hostname;
  }

  /** Returns only the path component for the store endpoint. */
  public getStoreEndpointPath(): string {
    // const dsn = this._dsnObject;
    // return `${dsn.path ? `/${dsn.path}` : ''}/api/${dsn.projectId}/store/`;
    // return `/custom/${dsn.projectId}/store/`;

    return '';
  }

  /** Returns an object that can be used in request headers. */
  public getRequestHeaders(clientName: string, clientVersion: string): { [key: string]: string } {
    const dsn = this._dsnObject;
    const header = [`Sentry sentry_version=${SENTRY_API_VERSION}`];
    header.push(`sentry_timestamp=${new Date().getTime()}`);
    header.push(`sentry_client=${clientName}/${clientVersion}`);
    header.push(`sentry_key=${dsn.user}`);
    if (dsn.pass) {
      header.push(`sentry_secret=${dsn.pass}`);
    }
    return {
      'Content-Type': 'application/json',
      'X-Sentry-Auth': header.join(', '),
    };
  }

  /** Returns the url to the report dialog endpoint. */
  public getReportDialogEndpoint(
    dialogOptions: {
      [key: string]: any;
      user?: { name?: string; email?: string };
    } = {},
  ): string {
    const dsn = this._dsnObject;
    const endpoint = `${this._getBaseUrl()}${dsn.path ? `/${dsn.path}` : ''}/api/embed/error-page/`;

    const encodedOptions = [];
    encodedOptions.push(`dsn=${dsn.toString()}`);
    for (const key in dialogOptions) {
      if (key === 'user') {
        if (!dialogOptions.user) {
          continue;
        }
        if (dialogOptions.user.name) {
          encodedOptions.push(`name=${encodeURIComponent(dialogOptions.user.name)}`);
        }
        if (dialogOptions.user.email) {
          encodedOptions.push(`email=${encodeURIComponent(dialogOptions.user.email)}`);
        }
      } else {
        encodedOptions.push(`${encodeURIComponent(key)}=${encodeURIComponent(dialogOptions[key] as string)}`);
      }
    }
    if (encodedOptions.length) {
      return `${endpoint}?${encodedOptions.join('&')}`;
    }

    return endpoint;
  }
}
