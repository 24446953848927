import { Event, Response, Status } from '@huya-sentry/types';
import { getGlobalObject, supportsReferrerPolicy } from '@huya-sentry/utils';
import * as qs from 'querystring';

import { BaseTransport } from './base';

const global = getGlobalObject<Window>();

/** `fetch` based transport */
export class FetchTransport extends BaseTransport {
  /**
   * @inheritDoc
   */
  public sendEvent(event: Event): Promise<Response> {
    //  change the body to form
    const defaultOptions: any = {
      // body: JSON.stringify(event),
      body: qs.stringify(event as any),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      // Despite all stars in the sky saying that Edge supports old draft syntax, aka 'never', 'always', 'origin' and 'default
      // https://caniuse.com/#feat=referrer-policy
      // It doesn't. And it throw exception instead of ignoring this parameter...
      // REF: https://github.com/getsentry/raven-js/issues/1233
      referrerPolicy: (supportsReferrerPolicy() ? 'origin' : '') as ReferrerPolicy,
    };


    // tslint:disable-next-line: no-floating-promises
    /** 取消 report-trace 上报 */
    // this._buffer.add(
    //   global.fetch(this.hscanUrl, defaultOptions).then(response => ({
    //     status: Status.fromHttpCode(response.status),
    //   })),
    // );


    return this._buffer.add(
      global.fetch(this.url, defaultOptions).then(response => ({
        status: Status.fromHttpCode(response.status),
      })),
    );
  }
}
