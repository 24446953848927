import { Event, Response, Status } from '@huya-sentry/types';
import * as qs from 'querystring';

import { BaseTransport } from './base';
/** `XHR` based transport */
export class XHRTransport extends BaseTransport {
  /**
   * @inheritDoc
   */
  public sendEvent(event: Event): Promise<Response> {
    // tslint:disable-next-line: no-floating-promises
    /** 取消 report-trace 上报 */
    // this._buffer.add(
    //   new Promise<Response>((resolve, reject) => {
    //     const request = new XMLHttpRequest();

    //     request.onreadystatechange = () => {
    //       if (request.readyState !== 4) {
    //         return;
    //       }

    //       if (request.status === 200) {
    //         resolve({
    //           status: Status.fromHttpCode(request.status),
    //         });
    //       }

    //       reject(request);
    //     };

    //     //  change the body to form
    //     request.open('POST', this.hscanUrl);
    //     request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    //     request.send(qs.stringify(event as any));
    //     // request.send(JSON.stringify(event));
    //   }),
    // );



    return this._buffer.add(
      new Promise<Response>((resolve, reject) => {
        const request = new XMLHttpRequest();

        request.onreadystatechange = () => {
          if (request.readyState !== 4) {
            return;
          }

          if (request.status === 200) {
            resolve({
              status: Status.fromHttpCode(request.status),
            });
          }

          reject(request);
        };

        //  change the body to form
        request.open('POST', this.url);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        request.send(qs.stringify(event as any));
        // request.send(JSON.stringify(event));
      }),
    );
  }
}
